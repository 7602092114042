/* main */
.swiper-pagination-bullet {
  display: inline-block;
  width: 50px;
  height: 6px;
  margin: 0 5px;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.swiper-pagination {
  left: 50%;
  transform: translateX(-50%);
}
.swiper-pagination-bullet-active {
  background-color: #fff;
}

.f-button-l {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
}
.f-button-r {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 1;
  height: 37.6rem;
  background-color: rgba(19, 21, 41, 1);
}

/* main */
.light {
  width: 100%;
  max-height: 40rem;
  max-width: 40rem;
}
.text-shadow {
  text-shadow: 2px 2px 2px #000;
}
.marketBox {
  border: 1px solid #e3e3e3;
}
.boxHeader {
  background-color: #e3e3e3;
}
.brachMapRatio {
  width: 100%;
  padding-top: calc(100% * 470 / 569);
}
.brachMapRatio2 {
  width: 100%;
  padding-top: calc(100% * 470 / 1156);
}
.f-button-l {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.f-button-r {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: rgba(19, 21, 41, 1);
}

@media screen and (max-width: 599px) {
  .brachMapRatio2 {
    width: 100%;
    padding-top: 100%;
  }
  .rightGuide::after {
    width: 0;
  }
}
