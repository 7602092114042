.branchRatio {
  width: 100%;
  padding-top: 100%;
}
@media screen and (max-width: 600px) {
  .branchRatio {
    width: 100%;
    padding-top: 0;
  }
}

/* map */
.land:hover {
  fill: #481c7866;
}
.select {
  fill: var(--main1);
}
/* search */
.region-hidden {
  display: hidden;
}
.name-hidden {
  display: hidden;
}
.address-hidden {
  display: hidden;
}
