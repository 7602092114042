.vicop {
  filter: drop-shadow(30px 30px 80px rgba(0, 0, 0, 0.2));
}
.check {
  padding-top: 100%;
  background: rgb(73, 28, 120, 0.6);
}
.textTop1 {
  top: 38%;
}
.textTop2 {
  top: 35%;
}
/* useful */
.usefulRatio {
  width: 100%;
  padding-top: calc(100% * 164 / 372);
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis ellipsis;
}
/* isntagram */
.feed {
  width: 100%;
  padding-top: 100%;
  position: relative;
  background-color: #491c78;
}
.feedLink {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
}
.feedImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* slide */
.sanpuryvideoRatio {
  width: 100%;
  padding-top: calc(100% * 9 / 16);
}
.titleText {
  width: 100%;
  top: 35%;
}
@media screen and (max-width: 720px) {
  .titleText {
    width: 100%;
    top: 45%;
  }
  .textTop2 {
    top: 30%;
  }
}
