.noScrollBar::-webkit-scrollbar {
  display: none;
}
.noScrollBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.smooth {
  scroll-behavior: smooth;
}
.banScroll {
  overflow: hidden;
  height: 100vh;
}
.py-132 {
  padding-top: 13.2rem;
  padding-bottom: 13.2rem;
}
.pt-132 {
  padding-top: 13.2rem;
}
.pb-132 {
  padding-bottom: 13.2rem;
}
.recruit_border li {
  border: 1px solid #e6e7e9;
  border-right: 0;
}
.recruit_border li:last-child {
  border-right: 1px solid #e6e7e9;
}
.recruit_border .selectList {
  border-top: 2px solid #e2470e;
}
.map-m-border {
  border-bottom: 1px solid #e6e7e9;
}
@media screen and (max-width: 640px) {
  .recruit_border li {
    border: 1px solid #e6e7e9;
    border-right: 0;
  }
  .recruit_border li:nth-child(1),
  .recruit_border li:nth-child(2) {
    border-bottom: 0;
  }
  .recruit_border li:nth-child(2n) {
    border-right: 1px solid #e6e7e9;
  }
}
