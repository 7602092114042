/* ------------------------------------------header */
#header {
  transition: background-color 0.2s linear;
}
.mainTab {
  position: relative;
}
.mainTab::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: calc(50% - 1.5rem);
  transform: scaleX(0);
  transition: transform 0.2s;
}
.mainTabLine_black::after {
  border-top: 2px solid #000;
}
.mainTabLine_white::after {
  border-top: 2px solid #fff;
}

.mainTabLine::after {
  transform: scaleX(1);
}

#GNB .submenu {
  padding-top: 1rem;
  top: 4rem;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.2s;
}

.open #GNB .submenu {
  transform: scaleY(1);
  transition: transform 0.2s;
}
#MNB .submenu {
  height: auto;
  width: 100%;
  text-align: center;
  margin-top: 0;
}

.hover\:font-bold:hover {
  font-weight: bold;
}
.cutton-open {
  display: block;
  width: 100%;
  min-height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.setlang > a {
  position: relative;
}
.setlang > a:hover {
  opacity: 0.5;
}
.setlang > .langLine_black::after {
  content: "";
  display: block;
  width: 24px;
  height: 2px;
  position: absolute;
  background-color: #000;

  left: calc(50% - 12px);
  bottom: -2px;
}
.setlang > .langLine_white::after {
  content: "";
  display: block;
  width: 24px;
  height: 2px;
  position: absolute;
  background-color: #fff;

  left: calc(50% - 12px);
  bottom: -2px;
}
.menuInvert {
  filter: invert(100%);
}
@media (min-width: 1024px) {
  .cutton-open {
    display: none;
  }
}
.nav-hide {
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: transform 0.3s;
}
.nav-open {
  top: 0;
  left: none;
  right: 0;
  transform: translateX(0);
  transition: transform 0.3s;
}

/* footer */
.rightGuide {
  position: relative;
}
.rightGuide::after {
  content: "";
  position: absolute;
  right: 0;
  top: 15%;
  height: 70%;
  width: 1px;
  background-color: #aaa;
}
/* subheader */
.prd_back {
  background: url(/src/assets//images/subheader/product.jpg) no-repeat center
    center / cover;
}
.makt_back {
  background: url(/src/assets//images/subheader/branch.jpg) no-repeat center
    center / cover;
}
#SNB .smm,
#SNB .sms {
  border-top: 3px solid #e2470e;
}

.linebar::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  width: 1px;
  height: 24px;
  top: calc(50% - 12px);
  background-color: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 1024px) {
  .prd_back {
    background: url(/src/assets//images/subheader/product-m.jpg) no-repeat
      bottom center / cover;
  }
  .makt_back {
    background: url(/src/assets//images/subheader/branch-m.jpg) no-repeat bottom
      center / cover;
  }
}

@media screen and (max-width: 640px) {
  .linebar::after {
    width: 0;
  }
  .border-b-button {
    border-bottom: 1px solid var(--main2);
  }
  .border-b-text::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0.8rem;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 10rem);
    height: 2px;
    background-color: var(--main2);
    animation: normal;
  }
}
